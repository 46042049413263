<template>
    <footer class="page-footer font-small text-md-left">
        <div class="copyrights">
            <div class="container">

                <!-- Content -->
                <div class="row footer-content">
                    <div>
                        <p>
                            {{
                            $t('Copyrights', {
                                year: currentYear,
                                company: channelName || 'Visaic, Inc'
                            })
                            }}
                        </p>
                        <terms-policy-popup/>
                    </div>

                    <div class="d-flex align-items-center justify-content-center powered-logo-wrapper">
                    </div>


                    <!-- Links -->
                    <div>
                        <div class="copyrights-menu copyright-links fright">
                            <app-link v-for="item in getSubHeaderLinks"
                                         :key="item.url"
                                         class="footer-links" :to="item.url" exact>
                                {{item.name}}
                            </app-link>
                            <template v-if="authUser">
                                <p class="footer-links" @click="logout">
                                    {{ $t('Logout') }}
                                </p>

                            </template>
                            <template v-else>
                                <app-link class="footer-links" to="/register">
                                  {{ $t('Register') }}
                                </app-link>
                                <app-link class="footer-links"  to="/login">
                                    {{ $t('Login') }}
                                </app-link>
                            </template>
                        </div>
                        <div class="social-wrapper">
                            <social-buttons/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
    import {mapActions, mapState} from "vuex";
    import SocialButtons from "../../components/base-components/buttons/Social/SocialButtons";
    import AppLink from "@/components/base-components/AppLink";
    import TermsPolicyPopup from "../../components/base-components/termsPolicyPopup";

    export default {
        name: 'FooterComponent',
        data() {
            return {
                backUrl: process.env.VUE_APP_URL,
                currentYear: (new Date()).getFullYear()
            }
        },
        methods: {
            ...mapActions(['DO_LOGOUT', 'GET_API_KEY']),
            logout() {
                this.DO_LOGOUT()
                    .then(() => {
                        this.$store.commit('RESET_STATE');
                        this.$router.go(this.$router.currentRoute);
                    });
            },

        },
        computed: {
            ...mapState({
                channelMenu: state => state.channel.menu,
                authUser: state => state.auth.user,
                channelSettings: state => state.channel.settings,
            }),
            getSubHeaderLinks() {
                return this.channelMenu.filter((item) => {
                    if (item.position === "footer") {
                        return item
                    }
                    if (item.position === "both") {
                        return item
                    }
                });
            },
            channelName(){
                return this.channelSettings?.title
            }
        },
        components: {
            TermsPolicyPopup,
            AppLink,
            SocialButtons,
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/css/colors";
    @import "../../assets/css/mixins";

    .overflow-hidden {
        overflow: hidden;
    }
    .social-wrapper{
        display: flex;
        justify-content: flex-end;
        @include media-max(xs){
            justify-content: center;
            margin-top: 2rem;
        }
    }

    .copyrights-menu {
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        @include media-max(xs){
            justify-content: center;
        }

        .footer-links {
            color: var(--settings-footer-text-color);
            display: inline-block;
            margin: 0 10px;
            border-bottom: 0 !important;

            &:hover {
                color: var(--settings-footer-text-color);
            }
        }

        .footer-links:first-child {
            margin-left: 0;
        }
    }

    .copyrights {
        padding: 40px 0;
        font-size: .85rem;
        line-height: 1.8;
    }

    .footer-links {
        color: var(--settings-footer-text-color);
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
            color: var(--settings-footer-text-color);
        }
    }

    .container{
        @include media-max(lg){
            max-width: calc(100% - 2rem);
        }
    }

    .footer-content{
        display: grid;
        grid-template-columns: max-content 1fr max-content;
        > div:first-child{
            grid-area: privaci;
        }
        > div:nth-child(2){
            grid-area: powered;
        }
        > div:last-child{
            grid-area: menu;
        }
        grid-template-areas:
                "privaci powered menu";
        @include media-max(md){
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            grid-template-areas:
                "privaci menu"
                "powered powered";
        }
        @include media-max(xs){
            grid-template-columns: 1fr;
            gap: 2rem;
            grid-template-areas:
                "menu"
                "privaci"
                "powered";
        }
    }


</style>

<style lang="scss">
    @import "../../assets/css/colors";
    @import "../../assets/css/fonts";
    .page-footer{
        text-transform: none;
        font-family: var(--settings-footer-font-family);
        font-weight: 400;
        font-style: italic;
        background-color: var(--settings-footer-background);
        color: var(--settings-footer-text-color);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, .1);
        font-size: 14px;
    }


    .powered-logo{
        img {
            margin: 0 auto;
            height: 36px;
            max-width: 90vw;
            display: block;
            object-fit: contain;
        }
    }

</style>

