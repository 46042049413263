<template>
  <div>
    <p>
      <span class="links" @click="showModal('terms_conditions')">{{ $t('Terms & Conditions') }}</span>
      / <span class="links" @click="showModal('privacy_policy')">{{ $t('Privacy Policy') }}</span>
    </p>
    <modal ref="modalForPage">
      <template v-slot:header>
        <h1 class="modal__title">{{pageTitle}}</h1>
      </template>

      <template v-slot:body>
        <div class="wrapper">
          <div v-html="pageContent"></div>
        </div>
      </template>

      <template v-slot:footer>
        <div class="close_wrapper">
          <button class="btn" @click="$refs.modalForPage.closeModal()">Close</button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import Modal from "./Modal";

  export default {
    name: "termsPolicyPopup",
    components: {Modal},
    data(){
      return {
        pageContent: null,
        pageTitle: null,
        page: null,
      }
    },
    computed: {
      ...mapState({
        channelPages: state => state.channel.pages,
      }),
    },
    methods: {
      ...mapActions({getChannelPage: 'GET_CHANNEL_PAGE'}),
      showModal(url) {
        let id = null;
        this.channelPages.forEach(page => {
          if (page.url === url || page.url === '/' + url || page.alt_name === url) {
            id = page.id;
          }
        });

        if (id != null) {
          this.getChannelPage(id)
              .then((data) => {
                this.page = data.data;
                this.pageContent = this.convertEditorJsonToHtml(this.page.content) ?? '';
                this.pageTitle = this.page.name ?? '';
                this.$refs.modalForPage.openModal();
              });
        } else {
          this.pageContent = "Add page for your channel with URL or Alternative name " + url;
          this.pageTitle = "Error!";
          this.$refs.modalForPage.openModal();
        }

      },
    },
    mounted() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const modal = urlParams.get('modal');
      if (modal==='terms_conditions'){
        this.modalForPage('terms_conditions');
      }
      if (modal==='privacy_policy'){
        this.modalForPage('privacy_policy');
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "src/assets/css/colors";
  .close_wrapper {
    float: right;

  .btn {
    border-radius: .25rem;
    background-color: rgb(239, 239, 239);

  }
  }

  .modal__header .modal__title {
    color: #444;
    line-height: 1.5;
    font-size: 24px;

  }

  .links {
    cursor: pointer;
    text-transform: capitalize;
  }
  .modal__body {
    .wrapper {
      margin: 1rem;
      max-height: 600px;
      overflow: auto;
      background-color: #fff;

      a {
        color: $link-accent;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          color: $error;
        }
      }
    }

  }
</style>